function init() {
  // show more
  let items = document.getElementsByClassName("more");
  for (let i = 0; i < items.length; i++) {
    const item = items.item(i);
    item.addEventListener("click", function(element) {
      const parent = element.target.parentElement.parentElement;
      parent.classList.toggle("open");
      element.preventDefault();
    });
  }

  // show more activities
  document.getElementsByClassName("more-activities").item(0).addEventListener("click", function(element) {
    document.getElementById("aktivnosti").classList.toggle("open");
    element.preventDefault();
  });

  // subscribe
  var form = document.getElementById("subscribe");
  form.addEventListener("submit", subscribe);
}

async function subscribe(e) {
  e.preventDefault();
  // get value from input
  var email = document.getElementById("email").value;
  var name = document.getElementById("name").value;
  if (email.length > 0 && name.length > 0) {
    var response = await fetch("https://api.ljubljana-brezhrupa.si/subscribe", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        name: name
      })
    });
    if (response.status == 204) {
      alert("Hvala za prijavo!");
    }
  }
}

window.addEventListener("load", async () => {
  init();
})